import qs from "query-string";
import axios from "axios";
import moment from "moment";
import momentTz from "moment-timezone";
import { fetchWhiteLabelSettingsByDomain } from "../utils/account";
// import { platform } from "chart.js";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:4000";

let jwtToken;

export function setJwtToken(token) {
  jwtToken = token;
}

export function extractParams(props) {
  const param = qs.parse(props.location.search);
  if (param) {
    return param;
  } else {
    return null;
  }
}

export const addressBuilder = (address) => {
  let finalAddress = "";
  if (address) {
    if (address && address.addressLines && address.addressLines.length > 0) {
      finalAddress = address.addressLines.join(", ");
    }
    if (address.locality) {
      finalAddress = finalAddress.concat(", ", address.locality);
    }
    if (address.postalCode) {
      finalAddress = finalAddress.concat(", ", address.postalCode);
    }
    if (address.regionCode) {
      finalAddress = finalAddress.concat(", ", address.regionCode);
    }
  }
  return finalAddress;
};

export const getWeekDayInFR = (day, t) => {
  let tsDay;
  switch (day) {
    case "MONDAY":
      tsDay = t("common:days-short.monday");
      break;
    case "TUESDAY":
      tsDay = t("common:days-short.tuesday");
      break;
    case "WEDNESDAY":
      tsDay = t("common:days-short.wednesday");
      break;
    case "THURSDAY":
      tsDay = t("common:days-short.thursday");
      break;
    case "FRIDAY":
      tsDay = t("common:days-short.friday");
      break;
    case "SATURDAY":
      tsDay = t("common:days-short.saturday");
      break;
    case "SUNDAY":
      tsDay = t("common:days-short.sunday");
      break;
    default:
      tsDay = "-";
  }
  return tsDay;
};

export const whitelistWebsite = (website) => {
  if (website.includes("doctolib.fr/")) {
    return false;
  } else if (website.includes("lafourchette.com")) {
    return false;
  } else if (website.includes("facebook.com")) {
    return false;
  } else {
    return true;
  }
};

export async function getToken(props, setToken) {
  const localToken = window.localStorage.getItem("userToken");
  if (!localToken) {
    const newToken = qs.parse(props.location.search);
    if (props.location.search.length < 30) {
      console.debug("Signin, reloading...");
      props.history.push("/signin");
    } else {
      window.localStorage.setItem("userToken", newToken.user);
      setJwtToken(newToken.user);
      setToken(newToken.user);
      console.debug("Dashboard, reloading...");
      props.history.push("/dashboard");
    }
  } else {
    setJwtToken(localToken);
  }
}

/**
 * Encode params to send them with http
 *
 * @param {Object{any}} params
 * @returns {any}
 */
function buildParams(params) {
  return Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
    )
    .join("&");
}

/**
 * Parse response
 *
 * @param {Response} response
 * @returns {Promise}
 */
function parse(response) {
  if (response.status >= 200 && response.status < 300) {
    // Successful response with json body
    const contentType = response.headers["content-type"];
    if (contentType && contentType.includes("application/json")) {
      try {
        let res = response.data;
        return res;
      } catch (e) {
        throw e;
      }

      // return (response
      //   .then((data) => Promise.resolve(data))
      //   .catch((e) => {
      //     if (e.message.includes("Unexpected end of JSON input")) {
      //       return Promise.resolve();
      //     }
      //     throw e;
      //   });
      // )
    }
    // Successful response with no json body
    return Promise.resolve();
  }
  // Error response with json body
  return response.then((e) => Promise.reject(e));
}

/**
 * Perform a fetch on the API.
 *
 * @param {string} endpoint - Endpoint to fetch.
 * @param {Object{
 *  jwt?: string
 *  params?: ApiParams
 *  method?: {Enum: GET, POST, DELETE, PUT}
 * }} options
 * @returns {Promise<Response>}
 */
export function fetchApi(endpoint, { params, method = "GET" } = {}) {
  // build URL
  const fullURL =
    API_URL +
    endpoint +
    (method === "GET" && params ? `?${buildParams(params)}` : "");
  // build headers
  const headers = {
    "Content-Type": "application/json",
    // Connection: "Keep-Alive",
    "Access-Control-Allow-Origin": "*",
  };
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  return axios({
    method,
    url: fullURL,
    headers,
    data: method !== "GET" && params ? params : undefined,
  }).then(parse);
}

export function fetchApiCSV(endpoint, { params, method = "GET" } = {}) {
  // build URL
  const fullURL =
    API_URL +
    endpoint +
    (method === "GET" && params ? `?${buildParams(params)}` : "");
  // build headers
  const headers = {
    "Content-Type": "text/csv",
    // Connection: "Keep-Alive",
    "Access-Control-Allow-Origin": "*",
    "Response-Type": "blob",
  };
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }
  axios({
    method,
    url: fullURL,
    headers,
    data: method !== "GET" && params ? params : undefined,
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "reviews.csv");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });
}

export async function fetchApiFile(
  endpoint,
  { params, method = "GET" } = {},
  updateProgress
) {
  // build URL
  const fullURL =
    API_URL +
    endpoint +
    (method === "GET" && params ? `?${buildParams(params)}` : "");
  // build headers
  const headers = {
    "content-type": "multipart/form-data",
    "Access-Control-Allow-Origin": "*",
  };
  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  let res = await axios({
    method,
    url: fullURL,
    headers,
    data: method !== "GET" && params ? params : undefined,
    onUploadProgress: (progressEvent) => {
      console.log(
        "Upload Progress: " +
          Math.round((progressEvent.loaded / progressEvent.total) * 100) +
          "%"
      );
      if (updateProgress) {
        updateProgress(
          Math.round((progressEvent.loaded / progressEvent.total) * 100)
        );
      }
    },
  });
  let imageUrl = await parse(res);
  return imageUrl;

  // return axios({
  //   method,
  //   url: fullURL,
  //   headers,
  //   data: method !== "GET" && params ? params : undefined,
  // }).then(parse);
}

let currentAssets = "";
export function newVersion(fetch: any): Promise<boolean> {
  if (process.env.NODE_ENV === "production") {
    return new Promise(function (resolve, reject) {
      fetch("/asset-manifest.json", { cache: "no-store" })
        .then((response) => {
          if (response.ok && response.status === 200) {
            response.json().then((json) => {
              const lastAsssets = JSON.stringify(json);
              if (currentAssets === "") {
                currentAssets = lastAsssets;
                resolve(false);
              } else {
                resolve(currentAssets !== lastAsssets);
                currentAssets = lastAsssets;
              }
            });
          } else {
            resolve(false);
          }
        })
        .catch((error) => {
          resolve(false);
        });
    });
  } else {
    // console.log("Triggered check refresh");
  }
  return Promise.resolve(false);
}

export const getSMSLength = (template, businessName, clientName, templates) => {
  let SMSlengthTotal = 0;
  let STOPSMS = 11;

  let index = templates.findIndex((temp) => temp.TemplateID === template);

  if (index !== -1) {
    let selectedTemplate = templates[index].Content;
    SMSlengthTotal = selectedTemplate.length;

    // if variable client_name // remove length client_name and add client_name length

    if (clientName) {
      SMSlengthTotal = SMSlengthTotal - 15 + clientName.length;
    }
    // if variable business_name // remove length client_name and add client_name length
    if (businessName) {
      SMSlengthTotal = SMSlengthTotal - 17 + businessName.length;
    }
    // Switch short URL length
    SMSlengthTotal = SMSlengthTotal - 14 + 23;
    // add STOP SMS
    SMSlengthTotal = SMSlengthTotal + STOPSMS;
  }

  return SMSlengthTotal;
};

// legacy
export const isAllowedToPublis = (website) => {
  if (website.includes("doctolib.fr/")) {
    return false;
  } else if (website.includes("lafourchette.com")) {
    return false;
  } else if (website.includes("facebook.com")) {
    return false;
  } else {
    return true;
  }
};

// used in post creation
export const isAllowedToPublish = (
  postType,
  selectedBusinesses,
  selectedFacebook,
  selectedInsta,
  selectedLinkedin,
  selectedTikTok,
  summary,
  postTitle,
  startDate,
  endDate,
  button,
  linkButton,
  GPostName,
  platforms,
  imageUrl,
  videoUrl,
  isContentValidated,
  isEditing,
  isPublished,
  issuePageAlert,
  isStories
) => {
  const platformChecks = {
    google: selectedBusinesses,
    facebook: selectedFacebook,
    instagram: selectedInsta,
    linkedin: selectedLinkedin,
    tiktok: selectedTikTok,
  };

  // Check if at least one page is selected for any platform
  let anyPageSelected = false;
  for (const platform of platforms) {
    if (platformChecks[platform] && platformChecks[platform].length > 0) {
      anyPageSelected = true;
      break;
    }
  }

  // Return false if no pages are selected
  if (!anyPageSelected) return false;

  if (!GPostName || GPostName.length <= 2) return false;

  if (postType === "offer" || postType === "event") {
    if (!postTitle || postTitle.length <= 2 || !startDate || !endDate)
      return false;
  } else if (postType === "news" || postType === "covid") {
    // Special case for Instagram Stories - don't require summary text
    if (
      isStories &&
      platforms &&
      platforms.includes("instagram") &&
      (imageUrl || videoUrl)
    ) {
      // For Stories, we only need an image or video and an Instagram page
      if (selectedInsta && selectedInsta.length > 0) {
        // Allow publishing without summary text
      } else {
        return false; // No Instagram page selected
      }
    } else if (!summary || summary.length <= 5) {
      return false; // Regular posts still need summary text
    }
  }

  if (
    button !== "none" &&
    button !== "CALL" &&
    (!linkButton || linkButton.length <= 5)
  )
    return false;

  if (platforms && platforms.includes("instagram") && !imageUrl && !videoUrl)
    return false;

  if (platforms && platforms.includes("google") && videoUrl) return false;

  if (platforms && platforms.length === 0) return false;

  if (!isContentValidated) return false;
  if (
    isEditing &&
    isPublished &&
    platforms &&
    !platforms.includes("google") &&
    !platforms.includes("facebook")
  )
    return false;

  // Add TikTok validation - require either video or image
  if (platforms && platforms.includes("tiktok") && !imageUrl && !videoUrl)
    return false;

  // if (issuePageAlert) return false;

  return true;
};

// legacy
export const isAllowedToCreateTemplat = (
  postType,
  summary,
  postTitle,
  startDate,
  endDate,
  button,
  linkButton,
  GPostName,
  dispatchFacebook
) => {
  if (GPostName && GPostName.length > 2) {
    if (
      button === "none" ||
      button === "CALL" ||
      (button !== "none" &&
        button !== "CALL" &&
        linkButton &&
        linkButton.length > 5)
    ) {
      if (postType === "offer" || postType === "event") {
        if (postTitle && postTitle.length > 2 && startDate && endDate) {
          return true;
        } else {
          return false;
        }
      } else if (postType === "news" || postType === "covid") {
        if (summary && summary.length > 5) {
          return true;
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

// used in post creation
export const isAllowedToCreateTemplate = (
  postType,
  summary,
  postTitle,
  startDate,
  endDate,
  button,
  linkButton,
  GPostName,
  platforms,
  imageUrl,
  videoUrl,
  isContentValidated,
  isStories
) => {
  if (!GPostName || GPostName.length <= 2) return false;

  if (postType === "offer" || postType === "event") {
    if (!postTitle || postTitle.length <= 2 || !startDate || !endDate)
      return false;
  } else if (postType === "news" || postType === "covid") {
    if (!summary || summary.length <= 5) return false;
  }

  if (
    button !== "none" &&
    button !== "CALL" &&
    (!linkButton || linkButton.length <= 5)
  )
    return false;

  if (platforms && platforms.includes("instagram") && !imageUrl && !videoUrl)
    return false;

  if (platforms && platforms.includes("tiktok") && !imageUrl && !videoUrl)
    return false;

  if (platforms && platforms.length === 0) return false;

  if (!isContentValidated) return false;

  return true;
};

export const defineEndDay = (startDate, startTime, endTime) => {
  moment.locale("en");
  let endDate;
  if (moment(startTime, "hh:mm").isAfter(moment(endTime, "hh:mm"))) {
    endDate = moment()
      .isoWeekday(startDate)
      .add("1", "day")
      .format("dddd")
      .toUpperCase();
  } else {
    endDate = startDate;
  }
  return endDate;
};

export const defineEndDate = (startDate, startTime, endTime) => {
  moment.locale("en");
  let startMoment = moment(startDate, "YYYY-MM-DD");
  let endMoment = startMoment.clone();

  if (moment(startTime, "HH:mm").isAfter(moment(endTime, "HH:mm"))) {
    endMoment.add(1, "day");
  }

  return endMoment.format("YYYY-MM-DD");
};

export const handleCanSendCampaign = () => {
  let nowHour = momentTz().tz("Europe/Paris").format("HH");
  let nowDay = momentTz().tz("Europe/Paris").format("dddd");
  if (nowDay === "dimanche") {
    return false;
  }
  if (+nowHour >= 20 && +nowHour <= 7) {
    return false;
  } else {
    return true;
  }
};

export const shouldDisplayModalPhone = async (user, account) => {
  // display phone modal if user is master of account, if user is free and if signed up today and if domain name is not a gmail
  if (account.Access === "global" && !account.IsPaying && !user.PhoneNumber) {
    // second step check if not free web hosting
    // check if signup is recent
    if (moment(user.CreatedAt).add(2, "days").isAfter(moment(), "days")) {
      console.log("third step passed");
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const defineFirstName = async (author) => {
  let fn = "";
  if (author) {
    if (author.includes(" ")) {
      fn = capitalizeFirstLetter(author.split(" ")[0]);
    } else {
      fn = capitalizeFirstLetter(author);
    }
  }
  return fn;
};

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const formatValuesAccessToPush = (recordAccess) => {
  let management = [];
  let optimisation = [];
  let publication = [];
  let other = [];
  let bulk = [];
  if (recordAccess.CanAccessReviewManagement) {
    management.push("reviewsManagement");
  }
  if (recordAccess.CanAccessQuestionManagement) {
    management.push("questionsManagement");
  }
  if (recordAccess.CanAccessPositionTracking) {
    management.push("localTracker");
  }
  if (recordAccess.CanAddKeywords) {
    management.push("addKeywords");
  }
  if (recordAccess.CanAccessReports) {
    management.push("reports");
  }
  if (recordAccess.CanAccessBulkManagement) {
    management.push("bulkManagement");
  }
  if (recordAccess.CanAccessStoreLocator) {
    management.push("storeLocatorManagement");
  }
  if (recordAccess.CanAccessWebsiteAudit) {
    optimisation.push("onSiteAudit");
  }
  if (recordAccess.CanAccessGMBAudit) {
    optimisation.push("gmbAudit");
  }
  if (recordAccess.CanAccessPublications) {
    publication.push("publications");
  }
  if (recordAccess.CanAccessBooster) {
    publication.push("reviewBooster");
  }
  if (recordAccess.CanAccessQRCodes) {
    publication.push("qrCodes");
  }
  if (recordAccess.CanAccessIntegrations) {
    other.push("integrations");
  }
  if (recordAccess.CanAccessWidget) {
    other.push("widget");
  }
  if (recordAccess.CanAccessMassGMBAudit) {
    other.push("massAudit");
  }
  if (recordAccess.CanAddBusiness) {
    other.push("canAddBusiness");
  }
  if (recordAccess.CanBulkEditHours) {
    bulk.push("hours");
  }
  if (recordAccess.CanBulkEditSpecialHours) {
    bulk.push("specialHours");
  }
  if (recordAccess.CanBulkEditPhone) {
    bulk.push("phone");
  }
  if (recordAccess.CanBulkEditDescription) {
    bulk.push("description");
  }
  if (recordAccess.CanBulkEditPhoto) {
    bulk.push("photos");
  }
  if (recordAccess.CanBulkEditWebsite) {
    bulk.push("website");
  }
  if (recordAccess.CanBulkEditServices) {
    bulk.push("services");
  }
  if (recordAccess.CanBulkEditFAQs) {
    bulk.push("faq");
  }
  if (recordAccess.CanBulkEditAttributes) {
    bulk.push("attributes");
  }
  if (recordAccess.CanAcceptPublications) {
    bulk.push("canAcceptPublications");
  }
  let res = {
    management,
    optimisation,
    publication,
    other,
    bulk,
  };
  return res;
};

export const defineLoginRoute = async (account, currentRoute) => {
  // default redirect on login
  // protection - if no access at all => logout and redirect to login
  // console.log(account);
  if (
    !account.CanAccessReviewManagement &&
    !account.CanAccessReports &&
    !account.CanAccessPositionTracking &&
    !account.CanAccessPublications &&
    !account.CanAccessBooster &&
    !account.CanAccessBulkManagement &&
    !account.CanAccessQuestionManagement &&
    !account.CanAccessMassGMBAudit &&
    !account.CanAccessGMBAudit &&
    !account.CanAccessWebsiteAudit &&
    !account.CanAccessQRCodes
  ) {
    // redirect to login
    return "logout";
  }
  let route = await defineDefaultLoginRoute(account);
  if (!currentRoute) {
    // send after login
    return route;
  } else {
    // check if can access else send to default redirect login
    let routeAccess = await checkIfHasAccessToCurrentRoute(
      account,
      currentRoute
    );
    if (routeAccess) {
      return undefined;
    } else {
      return route;
    }
  }
};

let defineDefaultLoginRoute = async (account) => {
  if (account.AuditOnlyMode) {
    return "audit/gmb";
  }
  return "dashboard";
  // if (account.Access === "global") {
  //   return "dashboard";
  // } else if (account.CanAccessReviewManagement) {
  //   return "feed";
  // } else if (
  //   account.CanAccessWebsiteAudit ||
  //   account.CanAccessGMBAudit ||
  //   account.CanAccessPositionTracking
  // ) {
  //   return "my-businesses/compare";
  // } else if (account.CanAccessPublications) {
  //   return "publications";
  // } else if (account.IsUsingReviewBooster && account.CanAccessBooster) {
  //   return "booster-reviews";
  // } else if (account.IsUsingBulkManagement && account.CanAccessBulkManagement) {
  //   return "bulk-management";
  // } else if (account.IsUsingReports && account.CanAccessReports) {
  //   return "reports";
  // } else if (account.CanAccessQuestionManagement) {
  //   return "questions";
  // } else if (account.CanAccessMassGMBAudit) {
  //   return "audit/gmb";
  // } else if (account.CanAccessQRCodes) {
  //   return "qr-codes";
  // }
};

const checkIfHasAccessToCurrentRoute = (account, currentRoute) => {
  switch (currentRoute) {
    case "dashboard":
      if (account.AuditOnlyMode) {
        return undefined;
      } else if (!account.AuditOnlyMode) {
        return currentRoute;
      } else {
        return undefined;
      }
    case "feed":
      if (account.AuditOnlyMode) {
        return undefined;
      } else if (!account.AuditOnlyMode && account.CanAccessReviewManagement) {
        return currentRoute;
      } else {
        return undefined;
      }
    case "publications":
      if (account.AuditOnlyMode) {
        return undefined;
      } else if (
        !account.AuditOnlyMode &&
        account.IsUsingPublications &&
        account.CanAccessPublications
      ) {
        return currentRoute;
      } else {
        return undefined;
      }
    case "booster-reviews":
      if (account.AuditOnlyMode) {
        return undefined;
      } else if (
        !account.AuditOnlyMode &&
        account.IsUsingReviewBooster &&
        account.CanAccessBooster
      ) {
        return currentRoute;
      } else {
        return undefined;
      }
    case "bulk-management":
      if (account.AuditOnlyMode) {
        return undefined;
      } else if (
        !account.AuditOnlyMode &&
        account.IsUsingBulkManagement &&
        account.CanAccessBulkManagement
      ) {
        return currentRoute;
      } else {
        return undefined;
      }
    case "reports":
      if (account.AuditOnlyMode) {
        return undefined;
      } else if (
        !account.AuditOnlyMode &&
        account.IsUsingReports &&
        account.CanAccessReports
      ) {
        return currentRoute;
      } else {
        return undefined;
      }
    default:
      return "dashboard";
  }
};

export const parseID = (id) => {
  return id.split("&LR")[1];
};

export const isListingDisconnected = async (business) => {
  try {
    console.log(business);
    let parsed = JSON.parse(business.ListingDetails);
    console.log(parsed);
    if (parsed?.metadata?.isDisconnected) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    console.log(e);
  }
};

export const cannotAddTeamMember = (account, users) => {
  try {
    if (account.AuditOnlyMode === 1) {
      if (users && users.length > 4) {
        return true;
      } else {
        return false;
      }
    } else if (account.Admin === 1) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log(e);
  }
};

export const updateColorsWhiteLabel = (data) => {
  try {
    document.documentElement.style.setProperty(
      "--primary-color",
      data.PrimaryColor
    );
    document.documentElement.style.setProperty(
      "--secondary-color",
      data.SecondaryColor
    );
  } catch (e) {
    console.log(e);
  }
};

export const defineWhiteLabelVar = async (domain, origin) => {
  try {
    // if (
    //   !domain.startsWith(
    //     "app.localranker.fr" || !domain.startsWith("localhost:3000")
    //   )
    // ) {
    // if (origin.startsWith("https")) {
    let info = await fetchWhiteLabelSettingsByDomain(domain);
    if (info) {
      window.localStorage.setItem("whitelabel", JSON.stringify(info));
      await updateColorsWhiteLabel(info);
      // set in localstorage and change colors
      // else
      // check if whitelabel exist in DB - if yes returns var then change logo - colors and store var in localStorage
      // } else {
      //   console.log("non secure domain name");
      //   // error non https domain
      // }
      // } else {
      //   // return error domain not allowed
      // }
      return true;
    } else {
      // empty localStorage
      window.localStorage.removeItem("whitelabel");
      return false;
    }
    // if localranker then do nothing
  } catch (e) {
    console.log(e);
  }
};

export const IsJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isAllowedToSeePositionTracking = (
  UserID,
  isAdmin,
  access,
  isPaying,
  accountID
) => {
  let whiteList = [
    1, 3, 502, 261, 1334, 1350, 1609, 1838, 1847, 1875, 1881, 1884, 1949,
  ];
  if (whiteList.includes(accountID) && isAdmin && access === "global") {
    return true;
  } else {
    return false;
  }
  // if (isAdmin && access === "global" && isPaying) {
  //   return true;
  // } else {
  //   return false;
  // }
  // const allowedUsers = [1, 3, 992, 1028, 797, 1376, 1786, 1880, 1283];
  // if (allowedUsers.includes(UserID)) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const defineExtensionToUse = (country) => {
  try {
    let lang;
    switch (country) {
      case "fr":
        lang = "fr";
        break;
      case "gf":
        lang = "fr";
        break;
      case "gp":
        lang = "fr";
        break;
      case "mq":
        lang = "fr";
        break;
      case "re":
        lang = "fr";
        break;
      case "us":
        lang = "com";
        break;
      case "ch":
        lang = "ch";
        break;
      case "ma":
        lang = "ma";
        break;
      default:
        lang = "com";
        break;
    }
    return lang;
  } catch (e) {
    console.log(e);
  }
};

export const formatPostionTrackingCSV = async (data, type) => {
  try {
    let object;
    if (type === "global") {
      object = {
        keyword: data?.keyword,
        volume: data?.volume?.Volume,
        related_businesses: data?.relatedBusinesses
          ? data?.relatedBusinesses.length
          : "-",
        business_name: "-",
        location: "-",
        localPack: "-",
        averageGoogle: data?.averageGoogle,
        evoGoogle: data?.googleEvo,
        lastGoogle: "-",
        lastMaps: "-",
        averageMaps: data?.averageMaps,
        evoMaps: data?.mapsEvo,
        url: "-",
        lastCrawl: "-",
      };
    } else {
      object = {
        keyword: "-",
        volume: "-",
        related_businesses: "-",
        business_name: data?.BusinessName + ", " + data?.Address,
        location: data?.Location,
        city: data?.city,
        localPack: data?.localPack ? data?.localPack : "-",
        averageGoogle: "-",
        lastGoogle: data?.googleLast,
        evoGoogle: data?.googleEvo,
        lastMaps: data?.mapsLast,
        averageMaps: "-",
        evoMaps: data?.mapsEvo,
        url: data?.rankingURL,
        lastCrawl: data?.lastCrawl ? data?.lastCrawl.split("T")[0] : "-",
      };
    }
    return object;
  } catch (e) {
    console.log(e);
  }
};

export const handleUTMContentChange = async (
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent
) => {
  console.log(utmContent);
  let utmToReturn = `?utm_source=${
    utmSource.includes("{{businessName}}") ? utmSource : encodeURI(utmSource)
  }&utm_medium=${
    utmMedium.includes("{{businessName}}") ? utmMedium : encodeURI(utmMedium)
  }${
    utmCampaign
      ? `&utm_campaign=${
          utmCampaign.includes("{{businessName}}")
            ? utmCampaign
            : encodeURI(utmCampaign)
        }`
      : ""
  }${
    utmContent
      ? `&utm_content=${
          utmContent.includes("{{businessName}}")
            ? utmContent
            : encodeURI(utmContent)
        }`
      : ""
  }`;
  // console.log(utmToReturn);
  return utmToReturn;
};

export const isSuperUser = (email) => {
  let whiteList = [
    "moinard.nico@gmail.com",
    "malo@localranker.fr",
    "jameybusiness123@gmail.com",
    "emilien@localranker.fr",
    "melanie@localranker.fr",
  ];
  if (whiteList.includes(email)) {
    return true;
  } else {
    return false;
  }
};

export const detectPlan = (planID) => {
  switch (planID) {
    // REPUTATION
    // testing pricing
    case "price_1LvLFuDZQukwkUkFGeljpwiT":
      return "reputation-montlhy";
    // prod pricing
    case "price_1LxDkpDZQukwkUkFpm8dxXPc":
      return "reputation-montlhy";
    // testing pricing
    case "price_1LooE8DZQukwkUkFCvdfiOFz":
      return "reputation-yearly";
    // prod pricing
    case "price_1LxDkpDZQukwkUkFkGCboM9n":
      return "reputation-yearly";
    // SEO LOCAL PRO
    // testing pricing
    case "price_1LvLKJDZQukwkUkFbM0Gijya":
      return "pro-monthly";
    // prod pricing
    case "price_1LxDk6DZQukwkUkF073oMiGS":
      return "pro-monthly";
    // testing pricing
    case "price_1LooEfDZQukwkUkFz9Ycu8x1":
      return "pro-yearly";
    // prod pricing
    case "price_1LxDk6DZQukwkUkF28xSMzNX":
      return "pro-yearly";
    default:
      return "old-plan";
  }
};

const fetchWithAuthentication = (url) => {
  return fetch(url, { mode: "no-cors" });
};

export const displayProtectedImage = async (imageUrl) => {
  // Fetch the image.
  const response = await fetchWithAuthentication(imageUrl);

  // Create an object URL from the data.
  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);

  // Update the source of the image.
  return objectUrl;
};

export const formatServices = async (services, categories) => {
  // array of objects of categories with services attached
  let cats = [];
  let primaryCat = {
    name: categories?.primaryCategory?.name,
    displayName: categories?.primaryCategory?.displayName,
    services: [],
    type: "primary",
  };
  cats.push(primaryCat);
  if (
    categories &&
    categories?.additionalCategories &&
    categories?.additionalCategories.length > 0
  ) {
    for (const addCat of categories?.additionalCategories) {
      let categoryToPush = {
        name: addCat?.name,
        displayName: addCat?.displayName,
        services: [],
        type: "additional",
      };
      cats.push(categoryToPush);
    }
  }

  // loop on each service detected
  if (services && services.length > 0) {
    for (let service of services) {
      let categoryId = service?.freeFormServiceItem
        ? service?.freeFormServiceItem?.category
        : service?.structuredServiceItem?.serviceTypeId;
      let index = cats.findIndex((c) => c.name === categoryId);
      if (index !== -1) {
        cats[index].services.push(service);
      }
      // fetch services for each category
    }
  }
  return cats;
};

export const defineDay = (openDay, t) => {
  let day;
  switch (openDay) {
    case "MONDAY":
      day = t("common:days.monday");
      break;
    case "TUESDAY":
      day = t("common:days.tuesday");
      break;
    case "WEDNESDAY":
      day = t("common:days.wednesday");
      break;
    case "THURSDAY":
      day = t("common:days.thursday");
      break;
    case "FRIDAY":
      day = t("common:days.friday");
      break;
    case "SATURDAY":
      day = t("common:days.saturday");
      break;
    case "SUNDAY":
      day = t("common:days.sunday");
      break;
    default:
      break;
  }
  return day;
};

export const defineChangeToPush = (change, t) => {
  let sentence;
  switch (change) {
    case "title":
      sentence = `${t("gbpAudit:title")}`;
      break;
    case "storefrontAddress":
      sentence = `${t("gbpAudit:address")}`;
      break;
    case "categories":
      sentence = `${t("gbpAudit:categories")}`;
      break;
    case "phoneNumbers":
      sentence = `${t("gbpAudit:phone")}`;
      break;
    case "websiteUri":
      sentence = `${t("gbpAudit:website")}`;
      break;
    case "regularHours":
      sentence = `${t("gbpAudit:hours")}`;
      break;
    case "specialHours":
      sentence = `${t("gbpAudit:special-hours")}`;
      break;
    case "profile":
      sentence = `${t("gbpAudit:description")}`;
      break;
    case "attributes":
      sentence = `${t("gbpAudit:attributes")}`;
      break;
    case "serviceItems":
      sentence = `${t("gbpAudit:services")}`;
      break;
    case "openInfo":
      sentence = `${t("gbpAudit:open")}`;
      break;
    case "photos":
      sentence = `${t("gbpAudit:photos")}`;
      break;
    case "videos":
      sentence = `${t("gbpAudit:videos")}`;
      break;
    default:
      sentence = null;
  }

  return sentence;
};

export const sortByRating = async (filteredTemplates, rating) => {
  try {
    // 1 array with the right tempaltes
    // 1 array with the remaining templates
    // concat them together
    // return the result
    let rightTemplates = [];
    let remainingTemplates = [];
    for (let template of filteredTemplates) {
      if (template.Rate && JSON.parse(template.Rate).includes(rating)) {
        rightTemplates.push(template);
      } else {
        remainingTemplates.push(template);
      }
    }
    return rightTemplates.concat(remainingTemplates);
  } catch (e) {
    console.log(e);
  }
};

export const isMediaSizeValid = async (
  mediaFile,
  platforms,
  mediaType,
  isReel,
  setImgHeight,
  setImgWidth,
  setImgWeight
) => {
  const limitations = {
    google: {
      photos: {
        minWidth: 250,
        minHeight: 250,
        maxWidth: 3000,
        maxHeight: 3000,
      },
      videos: {
        minWidth: 1280, // 720p resolution width
        minHeight: 720, // 720p resolution height
      },
    },
    instagram: {
      photos: {
        minWidth: 320,
        minHeight: 320,
        maxWidth: 1440,
        maxHeight: 2000,
      },
      videos: {
        minWidth: 320,
        maxWidth: 1090,
        minHeight: 320,
        maxHeight: 1920,
      },
      reels: {
        minWidth: 320,
        maxWidth: 1090,
        minHeight: 320,
        maxHeight: 1920,
      },
    },

    linkedin: {
      photos: {
        minWidth: 400,
        minHeight: 400,
        maxHeight: 4000,
        maxWidth: 4000,
      },
      videos: {
        minWidth: 256,
        minHeight: 144,
        maxWidth: 4096,
        maxHeight: 2304,
      },
    },
    facebook: {
      photos: {
        minWidth: 200,
        minHeight: 200,
        maxHeight: 2048,
        maxWidth: 2048,
      },
      videos: {
        minWidth: 600,
        minHeight: 600,
        maxWidth: 4000,
        maxHeight: 4000,
      },
    },
    tiktok: {
      photos: {
        minWidth: 360,
        minHeight: 360,
        maxWidth: 4096,
        maxHeight: 4096,
      },
      videos: {
        minWidth: 360,
        minHeight: 360,
        maxWidth: 4096,
        maxHeight: 4096,
      },
    },
  };

  const durationLimitations = {
    google: { min: 0, max: 60 },
    instagram: { min: 3, max: 90 },
    linkedin: { min: 3, max: 600 },
    facebook: { min: 3, max: 120 },
    tiktok: { min: 3, max: 600 },
  };
  const strictestLimitations = platforms.reduce(
    (strict, platform) => {
      const platformLimitations =
        limitations[platform][isReel ? "reels" : mediaType];
      return {
        minWidth: Math.max(strict.minWidth, platformLimitations.minWidth),
        minHeight: Math.max(strict.minHeight, platformLimitations.minHeight),
        maxWidth:
          strict.maxWidth === undefined
            ? platformLimitations.maxWidth
            : platformLimitations.maxWidth === undefined
            ? strict.maxWidth
            : Math.min(strict.maxWidth, platformLimitations.maxWidth),
        maxHeight:
          strict.maxHeight === undefined
            ? platformLimitations.maxHeight
            : platformLimitations.maxHeight === undefined
            ? strict.maxHeight
            : Math.min(strict.maxHeight, platformLimitations.maxHeight),
      };
    },
    { minWidth: 0, minHeight: 0 }
  );

  const strictestDurationLimitations = platforms.reduce(
    (strict, platform) => {
      const platformLimitations = durationLimitations[platform];
      return {
        min: Math.max(strict.min, platformLimitations.min),
        max: Math.min(strict.max, platformLimitations.max),
      };
    },
    { min: 0, max: Infinity }
  );

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const { minWidth, minHeight, maxWidth, maxHeight } =
          strictestLimitations;
        const width = img.width;
        const height = img.height;
        setImgWidth(width);
        setImgHeight(height);
        let round = mediaFile.size / 1024 / 1024;
        setImgWeight(round.toFixed(3));
        // check file size < 100 mb
        const isSmallerThan3mb = mediaFile.size / 1024 / 1024 < 3;
        if (!isSmallerThan3mb) {
          resolve(false);
        }
        if (platforms && platforms.includes("instagram")) {
          // check aspect ratio of image
          const aspectRatio = width / height;
          console.log(aspectRatio);
          // Instagram aspect ratio limitations (4:5 to 1.91:1)
          const minAspectRatio = 4 / 5;
          const maxAspectRatio = 1.91;
          if (aspectRatio < minAspectRatio || aspectRatio > maxAspectRatio) {
            resolve(false);
          }
        }
        if (
          width >= minWidth &&
          height >= minHeight &&
          (maxWidth === undefined || width <= maxWidth) &&
          (maxHeight === undefined || height <= maxHeight)
        ) {
          resolve(true);
        } else {
          resolve(false);
        }
      };
    };

    if (mediaType === "photos") {
      reader.readAsDataURL(mediaFile);

      // Add TikTok-specific checks for photos
      if (platforms.includes("tiktok")) {
        const img = new Image();
        img.onload = () => {
          const width = img.width;
          const height = img.height;

          // Check file size < 20 MB for TikTok images
          let isSmallerThan20mb = mediaFile.size / 1024 / 1024 < 20;
          if (!isSmallerThan20mb) {
            resolve(false);
            return;
          }

          // Check aspect ratio for TikTok (must be 9:16 or 16:9)
          const aspectRatio = width / height;
          const target916 = 9 / 16;
          const target169 = 16 / 9;
          const tolerance = 0.01; // Allow small deviation

          if (
            Math.abs(aspectRatio - target916) > tolerance &&
            Math.abs(aspectRatio - target169) > tolerance
          ) {
            resolve(false);
            return;
          }
        };
        img.src = URL.createObjectURL(mediaFile);
      }
    } else if (mediaType === "videos") {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.onloadedmetadata = () => {
        const width = video.videoWidth;
        const height = video.videoHeight;

        // Add TikTok-specific checks for videos
        if (platforms.includes("tiktok")) {
          // Check file size < 1 GB for TikTok videos
          let isSmallerThan1GB = mediaFile.size / 1024 / 1024 / 1024 < 1;
          if (!isSmallerThan1GB) {
            resolve(false);
            return;
          }

          // Check aspect ratio for TikTok (must be 9:16 or 16:9)
          const aspectRatio = width / height;
          const target916 = 9 / 16;
          const target169 = 16 / 9;
          const tolerance = 0.01; // Allow small deviation

          if (
            Math.abs(aspectRatio - target916) > tolerance &&
            Math.abs(aspectRatio - target169) > tolerance
          ) {
            resolve(false);
            return;
          }
        }
      };
      video.src = URL.createObjectURL(mediaFile);
    } else {
      reject(new Error("Invalid media type"));
    }
  });
};

export const isMediaSizeValidNew = async (
  mediaFile,
  platforms,
  mediaType,
  isReel,
  setImgHeight,
  setImgWidth,
  setImgWeight
) => {
  // Move limitations objects outside function if possible for better performance
  const limitations = {
    google: {
      photos: {
        minWidth: 250,
        minHeight: 250,
        maxWidth: 3000,
        maxHeight: 3000,
      },
      videos: {
        minWidth: 320,
        minHeight: 320,
      },
    },
    instagram: {
      photos: {
        minWidth: 320,
        minHeight: 320,
        maxWidth: 1440,
        maxHeight: 2000,
      },
      videos: {
        minWidth: 320,
        maxWidth: 1090,
        minHeight: 320,
        maxHeight: 1920,
      },
      reels: {
        minWidth: 320,
        maxWidth: 1090,
        minHeight: 320,
        maxHeight: 1920,
      },
    },
    linkedin: {
      photos: {
        minWidth: 400,
        minHeight: 400,
        maxHeight: 4000,
        maxWidth: 4000,
      },
      videos: {
        minWidth: 256,
        minHeight: 144,
        maxWidth: 4096,
        maxHeight: 2304,
      },
    },
    facebook: {
      photos: {
        minWidth: 200,
        minHeight: 200,
        maxHeight: 2048,
        maxWidth: 2048,
      },
      videos: {
        minWidth: 600,
        minHeight: 600,
        maxWidth: 4000,
        maxHeight: 4000,
      },
    },
    tiktok: {
      photos: {
        minWidth: 360,
        minHeight: 360,
        maxWidth: 4096,
        maxHeight: 4096,
      },
      videos: {
        minWidth: 360,
        minHeight: 360,
        maxWidth: 4096,
        maxHeight: 4096,
      },
    },
  };

  const durationLimitations = {
    google: { min: 0, max: 30 },
    instagram: { min: 3, max: 600 },
    linkedin: { min: 3, max: 600 },
    facebook: { min: 3, max: 120 },
    tiktok: { min: 3, max: 600 },
  };

  // Calculate strictest limitations once
  const strictestLimitations = platforms.reduce(
    (strict, platform) => {
      const platformLimitations =
        limitations[platform][isReel ? "reels" : mediaType];
      return {
        minWidth: Math.max(strict.minWidth, platformLimitations.minWidth),
        minHeight: Math.max(strict.minHeight, platformLimitations.minHeight),
        maxWidth: Math.min(
          strict.maxWidth ?? Infinity,
          platformLimitations.maxWidth ?? Infinity
        ),
        maxHeight: Math.min(
          strict.maxHeight ?? Infinity,
          platformLimitations.maxHeight ?? Infinity
        ),
        platform, // Keep track of which platform imposed the strictest limitation
      };
    },
    { minWidth: 0, minHeight: 0, maxWidth: Infinity, maxHeight: Infinity }
  );

  const strictestDurationLimitations = platforms.reduce(
    (strict, platform) => ({
      min: Math.max(strict.min, durationLimitations[platform].min),
      max: Math.min(strict.max, durationLimitations[platform].max),
      platform:
        durationLimitations[platform].min > strict.min
          ? platform
          : strict.platform,
    }),
    { min: 0, max: Infinity, platform: null }
  );

  const validationMessages = [];

  const validateDimensions = (width, height) => {
    const { minWidth, minHeight, maxWidth, maxHeight } = strictestLimitations;

    // Check basic dimensions
    if (width < minWidth) {
      validationMessages.push("errors.width-min");
    }
    if (height < minHeight) {
      validationMessages.push("errors.height-min");
    }
    if (width > maxWidth) {
      validationMessages.push("errors.width-max");
    }
    if (height > maxHeight) {
      validationMessages.push("errors.height-max");
    }

    const aspectRatio = width / height;
    let hasWarnings = false;

    // Platform-specific aspect ratio checks
    if (platforms.includes("instagram")) {
      if (isReel) {
        // Updated aspect ratio limits for Instagram reels
        if (aspectRatio < 0.01 || aspectRatio > 10) {
          validationMessages.push(`WARNING:warnings.instagram-reel-ratio`);
          hasWarnings = true;
        }
      } else {
        // Keep existing checks for regular Instagram posts - changed to warnings
        if (aspectRatio < 4 / 5 || aspectRatio > 1.91) {
          validationMessages.push(`WARNING:warnings.instagram-post-ratio`);
          hasWarnings = true;
        }
      }
    }

    // TikTok still requires strict aspect ratio validation
    if (platforms.includes("tiktok")) {
      const target916 = 9 / 16;
      const target169 = 16 / 9;
      const tolerance = 0.01;

      if (
        Math.abs(aspectRatio - target916) > tolerance &&
        Math.abs(aspectRatio - target169) > tolerance
      ) {
        validationMessages.push(
          `TikTok requires aspect ratio of either 9:16 or 16:9, got ${aspectRatio.toFixed(
            2
          )}:1`
        );
        return false;
      }
    }

    return true;
  };

  const validateFileSize = () => {
    const sizeInMB = mediaFile.size / (1024 * 1024);
    const sizeInGB = sizeInMB / 1024;

    // Update weight state
    setImgWeight(sizeInMB.toFixed(3));

    if (mediaType === "photos") {
      // Photos: 3MB general limit
      if (sizeInMB > 3) {
        validationMessages.push("errors.photo-size");
        return false;
      }

      // Platform-specific photo checks
      if (platforms.includes("tiktok") && sizeInMB > 20) {
        validationMessages.push("errors.tiktok-photo-size");
        return false;
      }
    } else if (mediaType === "videos") {
      // Videos: 100MB general limit
      if (sizeInMB > 100) {
        validationMessages.push("errors.video-size");
        return false;
      }

      // Platform-specific video checks
      if (platforms.includes("google") && sizeInMB > 20) {
        validationMessages.push("errors.google-video-size");
        return false;
      }

      if (platforms.includes("tiktok") && sizeInGB > 1) {
        validationMessages.push("errors.tiktok-video-size");
        return false;
      }
    }

    return true;
  };

  const validateDuration = (duration) => {
    const { min, max, platform } = strictestDurationLimitations;

    if (duration < min) {
      validationMessages.push("errors.video-duration-min");
      return false;
    }
    if (duration > max) {
      validationMessages.push("errors.video-duration-max");
      return false;
    }

    return true;
  };

  return new Promise((resolve, reject) => {
    try {
      if (mediaType === "photos") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onerror = () => {
            validationMessages.push("errors.load-failed");
            resolve({ isValid: false, messages: validationMessages });
          };

          img.onload = () => {
            setImgWidth(img.width);
            setImgHeight(img.height);

            const isValidSize = validateFileSize();
            const isValidDimensions = validateDimensions(img.width, img.height);

            resolve({
              isValid: isValidSize && isValidDimensions,
              messages: validationMessages,
            });
          };

          img.src = e.target.result;
        };

        reader.onerror = () => {
          validationMessages.push("errors.read-failed");
          resolve({ isValid: false, messages: validationMessages });
        };

        reader.readAsDataURL(mediaFile);
      } else if (mediaType === "videos") {
        const video = document.createElement("video");
        video.preload = "metadata";

        video.onerror = () => {
          validationMessages.push("errors.load-failed");
          resolve({ isValid: false, messages: validationMessages });
        };

        video.onloadedmetadata = () => {
          setImgWidth(video.videoWidth);
          setImgHeight(video.videoHeight);

          const isValidSize = validateFileSize();
          const isValidDimensions = validateDimensions(
            video.videoWidth,
            video.videoHeight
          );
          const isValidDuration = validateDuration(video.duration);

          resolve({
            isValid: isValidSize && isValidDimensions && isValidDuration,
            messages: validationMessages,
          });
        };

        video.src = URL.createObjectURL(mediaFile);
      } else {
        validationMessages.push("errors.invalid-media-type");
        resolve({ isValid: false, messages: validationMessages });
      }
    } catch (error) {
      validationMessages.push("errors.unexpected");
      resolve({ isValid: false, messages: validationMessages });
    }
  });
};

export const returnDimensionsToReformatImg = async (
  mediaFile,
  platforms,
  mediaType,
  isReel
) => {
  if (mediaType !== "photos") {
    return Promise.reject(new Error("Invalid media type"));
  }

  const limitations = {
    google: {
      photos: {
        minWidth: 250,
        minHeight: 250,
        maxWidth: 3000,
        maxHeight: 3000,
      },
    },
    instagram: {
      photos: {
        minWidth: 320,
        minHeight: 320,
        maxWidth: 1440,
        maxHeight: 2000,
      },
    },
    linkedin: {
      photos: {
        minWidth: 400,
        minHeight: 400,
        maxHeight: 4000,
        maxWidth: 4000,
      },
    },
    facebook: {
      photos: {
        minWidth: 200,
        minHeight: 200,
        maxHeight: 2048,
        maxWidth: 2048,
      },
    },
    tiktok: {
      photos: {
        minWidth: 360,
        minHeight: 360,
        maxWidth: 4096,
        maxHeight: 4096,
      },
    },
  };

  const strictestLimitations = platforms.reduce(
    (strict, platform) => {
      const platformLimitations = limitations[platform].photos;
      return {
        minWidth: Math.max(strict.minWidth, platformLimitations.minWidth),
        minHeight: Math.max(strict.minHeight, platformLimitations.minHeight),
        maxWidth:
          strict.maxWidth === undefined
            ? platformLimitations.maxWidth
            : platformLimitations.maxWidth === undefined
            ? strict.maxWidth
            : Math.min(strict.maxWidth, platformLimitations.maxWidth),
        maxHeight:
          strict.maxHeight === undefined
            ? platformLimitations.maxHeight
            : platformLimitations.maxHeight === undefined
            ? strict.maxHeight
            : Math.min(strict.maxHeight, platformLimitations.maxHeight),
      };
    },
    { minWidth: 0, minHeight: 0 }
  );

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.src = e.target.result;
      img.onload = () => {
        const { minWidth, minHeight, maxWidth, maxHeight } =
          strictestLimitations;
        const width = img.width;
        const height = img.height;
        const aspectRatio = width / height;
        let round = mediaFile.size / 1024 / 1024;

        // Always return recommendations regardless of aspect ratio or size
        let recommendedWidth = width;
        let recommendedHeight = height;

        if (
          width < minWidth ||
          height < minHeight ||
          width > maxWidth ||
          height > maxHeight
        ) {
          if (width < minWidth || height < minHeight) {
            if (width < minWidth) {
              recommendedWidth = minWidth;
              recommendedHeight = minWidth / aspectRatio;
            }
            if (height < minHeight) {
              recommendedHeight = minHeight;
              recommendedWidth = minHeight * aspectRatio;
            }
          } else {
            if (width > maxWidth) {
              recommendedWidth = maxWidth;
              recommendedHeight = maxWidth / aspectRatio;
            }
            if (height > maxHeight) {
              recommendedHeight = maxHeight;
              recommendedWidth = maxHeight * aspectRatio;
            }
          }

          // Ensure both dimensions are within limits
          if (recommendedWidth > maxWidth) {
            recommendedWidth = maxWidth;
            recommendedHeight = maxWidth / aspectRatio;
          }
          if (recommendedHeight > maxHeight) {
            recommendedHeight = maxHeight;
            recommendedWidth = maxHeight * aspectRatio;
          }
          if (recommendedWidth < minWidth) {
            recommendedWidth = minWidth;
            recommendedHeight = minWidth / aspectRatio;
          }
          if (recommendedHeight < minHeight) {
            recommendedHeight = minHeight;
            recommendedWidth = minHeight * aspectRatio;
          }

          resolve({
            shouldResize: true,
            recommendedWidth: Math.round(recommendedWidth),
            recommendedHeight: Math.round(recommendedHeight),
          });
        } else {
          resolve({
            shouldResize: false,
            recommendedWidth: width,
            recommendedHeight: height,
          });
        }
      };
    };

    reader.readAsDataURL(mediaFile);
  });
};

export const isContentValid = (
  platforms,
  postSummary,
  maxLength,
  linkButton,
  t,
  selectedFacebook,
  selectedInsta,
  selectedLinkedin,
  selectedTikTok
) => {
  let resp = {
    isValid: true,
    message: "",
  };

  if (platforms.includes("google")) {
    let regexPhone =
      /(\+33[\s]*[1-9](?:[\s]*\d{2}){4}\b|0[1-9](?:[\s.]?\d{2}){4}\b)/;
    let hasNumber = regexPhone.test(postSummary);
    if (hasNumber) {
      resp.isValid = false;
      resp.message = t("publication:phone-gbp");
      return resp;
    }
  }

  const hasVariables =
    postSummary?.includes("{{") || linkButton?.includes("{{");
  if (
    (platforms && platforms.includes("instagram") && hasVariables) ||
    (platforms && platforms.includes("linkedin") && hasVariables) ||
    (platforms && platforms.includes("facebook") && hasVariables) ||
    (platforms && platforms.includes("tiktok") && hasVariables)
  ) {
    // check if any of the selectedPages Array from each socials doesn't have an object with BusinessDBID
    if (
      platforms.includes("instagram") &&
      selectedInsta.filter((e) => e.BusinessDBID).length === 0
    ) {
      resp.isValid = false;
      resp.message = t("publication:pages-not-linked-variables");
      return resp;
    }
    if (
      platforms.includes("linkedin") &&
      selectedLinkedin.filter((e) => e.BusinessDBID).length === 0
    ) {
      resp.isValid = false;
      resp.message = t("publication:pages-not-linked-variables");
      return resp;
    }
    if (
      platforms.includes("facebook") &&
      selectedFacebook.filter((e) => e.BusinessDBID).length === 0
    ) {
      resp.isValid = false;
      resp.message = t("publication:pages-not-linked-variables");
      return resp;
    }
    if (
      platforms.includes("tiktok") &&
      selectedTikTok.filter((e) => e.BusinessDBID).length === 0
    ) {
      resp.isValid = false;
      resp.message = t("publication:pages-not-linked-variables");
      return resp;
    }
    resp.isValid = true;
    resp.message = t("publication:variables-bug");
    return resp;
  }

  if (postSummary.length > maxLength) {
    resp.isValid = false;
    resp.message = t("publication:too-long", { maxLength });
    return resp;
  }

  return resp;
};

export const findHighestDocCount = (scansPerInterval, reviewsPerInterval) => {
  let scanPerIntervalHighest = Math.max(
    ...scansPerInterval?.buckets.map((interval) => interval?.doc_count + 5)
  );
  if (reviewsPerInterval !== undefined) {
    let reviewsPerIntervalHighest = Math.max(
      ...reviewsPerInterval?.buckets.map((interval) => interval?.doc_count + 5)
    );
    return Math.max(scanPerIntervalHighest, reviewsPerIntervalHighest);
  } else {
    return scanPerIntervalHighest;
  }
};

export const validateAccess = async (
  globalUserAccess,
  receiveLiveReviews,
  rates,
  message,
  t
) => {
  try {
    // Destructure the properties from globalUserAccess for clarity
    const { management, optimisation, publication } = globalUserAccess;

    // Check if all three specified arrays are empty
    if (
      management.length === 0 &&
      optimisation.length === 0 &&
      publication.length === 0
    ) {
      message.warning(t("settings:team.modal.user-editted-warning"));
      return false;
    }
    if (
      (receiveLiveReviews && !rates) ||
      (receiveLiveReviews && rates && rates.length === 0)
    ) {
      message.warning(t("settings:notifications.pick-rates"));
      return false;
    }

    return true;
  } catch (e) {
    console.log(e);
    return false; // Return false in case of any error, or you can throw an error or handle it differently if you prefer.
  }
};

export const generateUniqueId = () => {
  const timestamp = Date.now(); // Current time in milliseconds
  const randomPart = Math.random().toString(36).substring(2, 15); // Random string
  return `${timestamp}-${randomPart}`;
};

export const defineStartDate = async (specialHours) => {
  // if specialHours is empty then return today else return the last entry of specialHours startDate + 1 day
  if (specialHours && specialHours.length > 0) {
    let lastEntry = specialHours[specialHours.length - 1];
    let startDate = moment(lastEntry.startDate)
      .add(1, "day")
      .format("YYYY-MM-DD");
    return startDate;
  } else {
    let startDate = moment().format("YYYY-MM-DD");
    return startDate;
  }
};

const routeAccessChecks = [
  {
    pattern: "dashboard",
    check: (account) => !account.AuditOnlyMode,
  },
  {
    pattern: "feed",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.CanAccessReviewManagement &&
      account.IsUsingReviews,
  },
  {
    pattern: "publications",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.IsUsingPublications &&
      account.CanAccessPublications,
  },
  {
    pattern: "booster-reviews",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.IsUsingReviewBooster &&
      account.CanAccessBooster,
  },
  {
    pattern: "bulk-management",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.IsUsingBulkManagement &&
      account.CanAccessBulkManagement,
  },
  {
    pattern: "reports",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.IsUsingReports &&
      account.CanAccessReports,
  },
  {
    pattern: "position-tracking",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.IsUsingPositionTracking &&
      account.CanAccessPositionTracking,
  },
  {
    pattern: "qr-codes",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.CanAccessQRCodes &&
      account.IsUsingQRCodes,
  },
  {
    pattern: "questions",
    check: (account) =>
      !account.AuditOnlyMode &&
      account.IsUsingQuestions &&
      account.CanAccessQuestionManagement,
  },
  {
    pattern: "audit/gmb",
    check: (account) => account.IsUsingMassGMBAudit || account.AuditOnlyMode,
  },
  {
    pattern: "local-audit",
    check: (account) => account.IsUsingGMBAudit,
  },
  {
    pattern: "local-assistant",
    check: (account) => account.IsUsingToDoList,
  },
];

export const checkIfHasAccessToCurrentRouteNew = (account, currentRoute) => {
  for (let route of routeAccessChecks) {
    if (currentRoute.includes(route.pattern)) {
      // Perform the check associated with this route pattern
      return route.check(account) ? currentRoute : undefined;
    }
  }

  // If no patterns match, default to "dashboard"
  // Note: You might want to reconsider this logic, as it might not be intuitive for all routes.
  return "dashboard";
};
